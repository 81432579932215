import { Alert, Link, Typography } from '@mui/material'

interface SecurityAlertProps {
    onClose: () => void
}

export const SecurityAlert: React.FC<SecurityAlertProps> = ({
    onClose
}: SecurityAlertProps): JSX.Element => {
    return (
        <Alert
            severity="warning"
            icon={false}
            onClose={onClose}
            sx={{
                bgcolor: '#F9AE23',
                color: '#FFFFFF',
                px: { xs: 2, md: 30 },
                py: 3
            }}
        >
            <Typography fontWeight="bold">
                We are closely tracking the cyber security incident impacting{' '}
                <Link
                    href="https://amwater.com/corp/security-faq"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    Virginia American Water (VAW)
                </Link>
                {'. '}
                AlexRenew&apos;s networks and systems were unaffected and remain secure;
                however, because we bill customers using water usage information from
                VAW meters, the incident will delay bill processing for some customers.
                Late fees will be waived during this time for all those affected.{' '}
                <Link
                    href="https://alexrenew.com/news/virgina-american-waters-cybersecurity-incident-may-delay-alexrenew-customer-bills-alexrenew-it"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    Click here
                </Link>{' '}
                to learn more.
            </Typography>
        </Alert>
    )
}
